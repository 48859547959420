import { Divider, Row, Col } from 'antd';
import CSVReader from 'react-csv-reader';
import { isEmpty } from 'ramda';
import validateCSVUpload from 'helpers/validateCSVUpload';
import validateCSVOnlyUpload from 'helpers/validateCSVOnlyUpload';
// import moment from 'moment';
import {
  TableBody,
  TableBordered,
  TableContainer,
  TableHeader,
} from './UploadCSV.styles';

const UploadCSV = ({
  exampleUrl,
  fileList,
  setFileList,
  errors,
  setErrors,
  dispatch,
  selected,
}) => {
  const resetData = () => {
    dispatch(setErrors([]));
    setFileList([]);
  };

  const validateCSV = (data) => {
    if (selected === 1) {
      return validateCSVUpload(data);
    }
    if (selected === 2) {
      return validateCSVOnlyUpload(data);
    }
    return {
      valid: false,
      errors: ['The selected option is invalid. Please try again.'],
      data: [],
    };
  };

  return (
    <>
      <Divider orientation="left">Upload CSV</Divider>
      <Row style={{ paddingBottom: 20 }}>
        <Col offset={2}>
          <p>
            You can download the {` `}
            <a target="_blank" href={exampleUrl} rel="noreferrer">
              CSV form
            </a>
            {` `}
            and reference of {` `}
            <a
              target="_blank"
              href="https://koobits.atlassian.net/wiki/spaces/KFED/pages/1578631169/Dynamic+Values+For+Student+Portal+Notifications"
              rel="noreferrer"
            >
              Dynamic Values on Notification.
            </a>
          </p>
          <CSVReader
            accept=".csv"
            parserOptions={{ header: true }}
            onError={(error) => {
              console.log(error);
            }}
            onFileLoaded={(data, fileInfo) => {
              console.log({ fileInfo });
              // check file format
              if (fileInfo.name.endsWith('.csv') === false) {
                dispatch(setErrors(['Please upload file with .csv format.']));
                return;
              }
              resetData();
              // full validation for the file
              // check if the file have duplications, else set file list
              const finalResult = validateCSV(data);
              if (finalResult.valid === false) {
                dispatch(setErrors(finalResult.errors));
                return;
              }
              // if valid then upload
              setFileList(finalResult.data);
            }}
          />
          {!isEmpty(errors) && (
            <ul style={{ color: 'red', marginTop: 30 }}>
              {errors.map((error, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>{error}</li>
              ))}
            </ul>
          )}
        </Col>
        <Col offset={2}>
          {!isEmpty(fileList) && (
            <div style={{ marginTop: selected === 2 && 40 }}>
              <h3>Preview</h3>
              {/* {JSON.stringify(fileList)} */}
              {fileList.length} {fileList.length <= 1 ? 'user' : 'users'} will
              be assigned for the notification
              {selected === 1 && (
                <TableContainer>
                  <TableBordered>
                    <TableHeader>
                      <tr>
                        <th>No.</th>
                        <th>User IDs</th>
                      </tr>
                    </TableHeader>
                    <TableBody>
                      {fileList.map((item, index) => (
                        <tr key={item.UserId}>
                          <td>{index + 1}</td>
                          <td>{item.UserId}</td>
                        </tr>
                      ))}
                    </TableBody>
                  </TableBordered>
                </TableContainer>
              )}
              {selected === 2 && (
                <TableContainer>
                  <TableBordered>
                    <TableHeader>
                      <tr>
                        <th>No.</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Actual Message</th>
                        <th>Notification Type</th>
                        <th>Title</th>
                        <th>User IDs</th>
                      </tr>
                    </TableHeader>
                    <TableBody>
                      {fileList.map((item, index) => (
                        <tr key={item.UserId}>
                          <td>{index + 1}</td>
                          <td>{item.StartDate}</td>
                          <td>{item.EndDate}</td>
                          <td>{item['Actual Message']}</td>
                          <td>{item.NotificationType}</td>
                          <td>{item.Title}</td>
                          <td>{item.UserId}</td>
                        </tr>
                      ))}
                    </TableBody>
                  </TableBordered>
                </TableContainer>
              )}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default UploadCSV;
