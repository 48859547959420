/* eslint-disable import/no-cycle */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb, Spin, Row, Col, Empty, Button, Pagination } from 'antd';
import NotificationsTable from 'components/Notifications/NotificationsTable';
// eslint-disable-next-line import/no-cycle
import Page from 'components/Shared/Page';
import {
  getNotifications,
  resetNotification,
} from 'store/notification/notificationSlice';
import { PaginationAlign } from './NotificationList.styles';

const NotificationListPage = () => {
  const totalItemCount = 10;
  const dispatch = useDispatch();
  const { notificationsLoading, notifications } = useSelector(
    (state) => state.notification
  );

  const { metaData, item } = notifications;

  useEffect(() => {
    dispatch(resetNotification());
    dispatch(getNotifications({ currentPage: 1, totalItemCount }));
  }, [dispatch]);

  const handleChange = (value) => {
    dispatch(getNotifications({ currentPage: value, totalItemCount }));
  };

  return (
    <Page>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Notifications</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Row justify="end">
          <Col style={{ padding: 5 }}>
            <Link to="/notification/static">
              <Button type="secondary">
                <span>Add Static Notification</span>
              </Button>
            </Link>
          </Col>
          <Col style={{ padding: 5 }}>
            <Link to="/notification/add">
              <Button type="primary">
                <span>Add New</span>
              </Button>
            </Link>
          </Col>
        </Row>
        {notificationsLoading && (
          <Row justify="center" align="middle" style={{ minHeight: '300px' }}>
            <Spin />
          </Row>
        )}
        {!notificationsLoading && (
          <>
            <Row justify="center" align="top">
              <Col span={24}>
                {item && item.length > 0 && (
                  <NotificationsTable pagination={false} notifications={item} />
                )}
                {item && item.length <= 0 && (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 60,
                      marginTop: '3rem',
                    }}
                    description={<span>No notifications found</span>}
                  />
                )}
              </Col>
            </Row>
            {metaData && (
              <PaginationAlign>
                <Pagination
                  current={metaData.pageNumber}
                  total={metaData.totalItemCount}
                  onChange={handleChange}
                />
              </PaginationAlign>
            )}
          </>
        )}
      </div>
    </Page>
  );
};

export default NotificationListPage;
