import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { BellOutlined, LogoutOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-cycle
import logout from 'helpers/logout';
import Notification from 'components/Notification/Notification.component';

const { Content, Sider } = Layout;

const Page = ({ children, selectedNavKeys }) => {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (value) => {
    setCollapsed(value);
  };

  const menuHandler = ({ key }) => {
    if (key === '2') {
      logout();
      Notification('logout');
    } else if (key === '1') {
      history.push('/');
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <div className="logo" />
        <Menu
          theme="dark"
          defaultSelectedKeys={selectedNavKeys || '1'}
          mode="inline"
          onClick={menuHandler}
        >
          <Menu.Item key="1" icon={<BellOutlined />}>
            Notifications
          </Menu.Item>
          <Menu.Item key="2" icon={<LogoutOutlined />}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Content style={{ margin: '0 16px' }}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default Page;
