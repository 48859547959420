import checkForDuplication from 'helpers/checkForDuplication';
import filterUserId from 'helpers/filterUserId';

const checkHeaderMatch = (data) => {
  return JSON.stringify(Object.keys(data)) === JSON.stringify(['UserId']);
};

const validateCSVUpload = (data) => {
  // validate it's in array of parseable string to integer
  // validate there's no duplicated value
  try {
    let res = false;
    const filteredData = filterUserId(data);
    // ensure the filtered data is not 0
    if (filteredData.length === 0) {
      return {
        valid: false,
        errors: ['The values cannot be empty.'],
        data: [],
      };
    }
    // check if the header matches in excel sheet
    res = checkHeaderMatch(filteredData[0]);
    if (!res) {
      return {
        valid: false,
        errors: ['The header does not match the .csv file.'],
        data: [],
      };
    }
    // check for duplication
    res = checkForDuplication(filteredData);
    if (res) {
      return {
        valid: false,
        errors: ['The UserIds are duplicated. Please check the file'],
        data: [],
      };
    }
    // for valid data
    return {
      valid: true,
      data: filteredData,
    };
  } catch (error) {
    return {
      valid: false,
      errors: [JSON.stringify(error)],
      data: [],
    };
  }
};

export default validateCSVUpload;
