import checkForDuplication from 'helpers/checkForDuplication';
import filterUserId from 'helpers/filterUserId';
import { isEmpty } from 'ramda';
import checkDateTimeFormat from './checkDateTimeFormat';

const checkHeaderMatch = (data) => {
  return (
    JSON.stringify(Object.keys(data)) ===
    JSON.stringify([
      'StartDate',
      'EndDate',
      'NotificationType',
      'Title',
      'Actual Message',
      'UserId',
    ])
  );
};

const checkEmptyColumns = (data) => {
  return data.every((item) => {
    const result =
      !isEmpty(item.StartDate) &&
      !isEmpty(item.EndDate) &&
      !isEmpty(item.NotificationType) &&
      !isEmpty(item.Title) &&
      !isEmpty(item['Actual Message']) &&
      !isEmpty(item.StartDate) &&
      !isEmpty(item.EndDate) &&
      !isEmpty(item.UserId);
    return result;
  });
};

const checkFormatForDates = (data) => {
  return data.every(
    (item) =>
      checkDateTimeFormat(item.StartDate) && checkDateTimeFormat(item.EndDate)
  );
};

// const checkFormatForDates = (data) => {
//   return data.every((item) => {
//     const startDate = moment(item.StartDate);
//     const endDate = moment(item.EndDate);
//     return (
//       moment(startDate, 'DD MMM yyyy HH:mm:ss').isValid() &&
//       moment(endDate, 'DD MMM yyyy HH:mm:ss').isValid()
//     );
//   });
// };

const checkNotificationType = (data) => {
  return data.every((item) => ['1', '2', '3'].includes(item.NotificationType));
};

const validateCSVOnlyUpload = (data) => {
  // validate it's in array of parseable string to integer
  // validate there's no duplicated value
  try {
    let res = false;
    const filteredData = filterUserId(data);
    // ensure the filtered data is not 0
    if (filteredData.length === 0) {
      return {
        valid: false,
        errors: ['The values cannot be empty.'],
        data: [],
      };
    }
    // check if the header matches in excel sheet
    res = checkHeaderMatch(filteredData[0]);
    if (!res) {
      return {
        valid: false,
        errors: ['The header does not match the .csv file.'],
        data: [],
      };
    }
    // check for duplication
    res = checkForDuplication(filteredData);
    if (res) {
      return {
        valid: false,
        errors: ['The UserIds are duplicated. Please check the file'],
        data: [],
      };
    }
    // ensure each column are not empty
    res = checkEmptyColumns(filteredData);
    if (!res) {
      return {
        valid: false,
        errors: ['The columns in the .csv cannot be empty'],
        data: [],
      };
    }

    // check notification type
    res = checkNotificationType(filteredData);
    if (!res) {
      return {
        valid: false,
        errors: ['Invalid notification type. Only 1,2,3 are allowed.'],
        data: [],
      };
    }

    // check if the format for start date and end date are within the right format
    res = checkFormatForDates(filteredData);
    if (!res) {
      return {
        valid: false,
        errors: [
          'Please ensures the dates format in dd MMM yyyy HH:mm:ss. \t Eg. 20 Aug 2021 10:20:28',
        ],
        data: [],
      };
    }

    // for valid data
    return {
      valid: true,
      data: filteredData,
    };
  } catch (error) {
    return {
      valid: false,
      errors: [JSON.stringify(error)],
      data: [],
    };
  }
};

export default validateCSVOnlyUpload;
