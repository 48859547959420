import styled from 'styled-components';

export const TableContainer = styled.div`
  overflow-y: scroll;
  width: ${(props) => (props.selected === 1 ? '300px' : '100%')};
  max-height: 250px;
`;

export const TableBordered = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const TableHeader = styled.thead`
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    background-color: #001528;
    color: #fff;
  }
`;

export const TableBody = styled.tbody`
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
`;
