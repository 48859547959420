export const notificationObj = {
  id: null,
  title: '',
  contentType: 1,
  status: 1,
  content: null,
  notificationType: null,
  duration: [],
  countries: [],
  levels: [],
  plans: [],
  purchaseDate: null,
  expiryDate: null,
  isUpgraded: true,
};

// "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
// "title": "string",
// "contentType": 1,
// "notificationType": 1,
// "content": "string",
// "startDate": "2021-06-18T02:35:42.506Z",
// "endDate": "2021-06-18T02:35:42.506Z",
// "status": 0,
// "isRead": true

// "id": "35f51905-c339-4457-8711-88958bd4d71f",
// "status": 1,
// "userId": 6335,
// "title": "K21 - B2B Medal Upsell 3\r",
// "contentType": 1,
// "notificationType": 2,
// "startDate": "2021-06-17T00:00:00+08:00",
// "endDate": "2021-06-17T23:59:59+08:00",

export default {};

// const initialValues = {
//   id: null,
//   title: '',
//   contentType: 1,
//   content: null,
//   notificationType: null,
//   startDate: '',
//   endDate: '',
//   subscriptionPlan: [],
//   country: [],
//   level: [],
//   purchaseDate: [],
//   expiryDate: [],
//   isUpgraded: true,
// };
