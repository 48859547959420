import React from 'react';
import { Table, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import {
  getNotifications,
  removeNotification,
  selectNotification,
} from 'store/notification/notificationSlice';
import { useDispatch, useSelector } from 'react-redux';
import Notification from 'components/Notification/Notification.component';
import { notificationTypeData } from 'constants/data';

const Link = (text, record) => {
  const history = useHistory();
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        history.push(`/notification/${record.id}`);
      }}
      type="link"
    >
      {text}
    </Button>
  );
};

const Actions = (record) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notification);

  const { metaData } = notifications;

  return (
    <>
      <Button
        onClick={(e) => {
          e.preventDefault();
          history.push(`/notification/${record.id}`);
        }}
        type="link"
      >
        View
      </Button>
      <Button
        onClick={(e) => {
          e.preventDefault();
          // history.push(`/notification/${record.key}`);
          dispatch(selectNotification(record));
          // eslint-disable-next-line no-alert
          const message = window.confirm('Do you really want to remove this?');
          if (message === true) {
            dispatch(removeNotification(record.id)).then(() => {
              Notification('notificationRemoved');
              dispatch(
                getNotifications({
                  currentPage: metaData.pageNumber,
                  totalItemCount: 10,
                })
              );
            });
          }
        }}
        type="link"
      >
        Delete
      </Button>
    </>
  );
};

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: Link,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Notification Type',
    key: 'notificationType',
    render: ({ notificationType }) => {
      const result = notificationTypeData.find(
        (item) => item.id === notificationType
      );
      return result.name;
    },
  },
  {
    title: 'Start Date',
    key: 'startDate',
    render: ({ startDate }) => moment(startDate).format('YYYY-MM-DD'),
  },
  {
    title: 'End Date',
    key: 'startDate',
    render: ({ endDate }) => moment(endDate).format('YYYY-MM-DD'),
  },
  {
    title: 'Action',
    key: 'action',
    render: Actions,
  },
];

const NotificationsTable = ({ pagination, notifications }) => {
  return (
    <Table
      rowKey="id"
      columns={columns}
      pagination={pagination}
      dataSource={notifications}
      style={{ marginTop: '2rem' }}
    />
  );
};
export default NotificationsTable;
