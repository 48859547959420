export const countryData = [
  {
    id: 'SGP',
    countryName: 'Singapore',
  },
  {
    id: 'TWN',
    countryName: 'Taiwan',
  },
  {
    id: 'IDN',
    countryName: 'Indonesia',
  },
  {
    id: 'JPN',
    countryName: 'Japan',
  },
  {
    id: 'MYS',
    countryName: 'Malaysia',
  },
  {
    id: 'PHL',
    countryName: 'Philippines',
  },
  {
    id: 'HKG',
    countryName: 'Hong Kong',
  },
];

export const levelData = [
  {
    id: 1,
    levelName: 'Primary 1',
  },
  {
    id: 2,
    levelName: 'Primary 2',
  },
  {
    id: 3,
    levelName: 'Primary 3',
  },
  {
    id: 4,
    levelName: 'Primary 4',
  },
  {
    id: 5,
    levelName: 'Primary 5',
  },
  {
    id: 6,
    levelName: 'Primary 6',
  },
];

export const planData = [
  {
    id: 1,
    name: 'Home-Based Learning Plan - Lite',
  },
  {
    id: 2,
    name: 'Home-Based Learning Plan - Plus',
  },
  {
    id: 3,
    name: 'Home-Based Learning Plan - Premium',
  },
  {
    id: 4,
    name: 'Home-Based Learning Plan - Math Olympiad',
  },
  {
    id: 5,
    name: 'Live Tutoring Plan - Quarterly',
  },
  {
    id: 6,
    name: 'Live Tutoring Plan - Annually',
  },
  {
    id: 7,
    name: 'KooBits Homework Service',
  },
  {
    id: 9,
    name: 'School Full',
  },
  {
    id: 10,
    name: 'School Lite',
  },
  {
    id: 11,
    name: 'School Trial',
  },
  {
    id: 12,
    name: 'Trial',
  },
  {
    id: 13,
    name: 'Standard',
  },
];

export const notificationTypeData = [
  {
    id: 1,
    name: 'Pop Up',
  },
  {
    id: 2,
    name: 'Page',
  },
  {
    id: 3,
    name: 'Banner',
  },
];
