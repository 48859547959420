/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import AuthenticatedRoute from 'components/Shared/AuthenticatedRoute';
import LoginRedirect from 'pages/LoginRedirect';
// import NotificationList from 'pages/Notifications/NotificationList';
// import NotificationRoutes from 'pages/Notifications';
import NotificationList from 'pages/Notifications/NotificationList';
import NotificationView from 'pages/Notifications/NotificationView';
import NotificationAdd from 'pages/Notifications/NotificationAdd';
import StaticNotificationView from 'pages/StaticNotifications/StaticNotificationView';
import Login from 'pages/Login/Login.component';

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_SUBDOMAIN,
});

const ExternalLogin = () => {
  const loginUrl = process.env.REACT_APP_ADMIN_LOGIN_URL;
  useEffect(() => {
    window.location.href = loginUrl;
  }, [loginUrl]);
  return null;
};

const Routes = () => {
  const env =
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_NETLIFY === 'true'
      ? 'dev'
      : 'prod';
  return (
    <Router history={history}>
      <Switch>
        <Route path="/redirect" component={LoginRedirect} />
        {/* <AuthenticatedRoute exact path="/" component={NotificationRoutes} /> */}
        <AuthenticatedRoute
          exact
          path="/notification/static"
          component={StaticNotificationView}
        />
        <AuthenticatedRoute
          exact
          path="/notification/add"
          component={NotificationAdd}
        />
        <AuthenticatedRoute
          exact
          path="/notification/:id"
          component={NotificationView}
        />
        <AuthenticatedRoute
          exact
          path="/notification"
          component={NotificationList}
        />
        <AuthenticatedRoute exact path="/" component={NotificationList} />
        <Route
          exact
          path="/login"
          component={env === 'dev' ? Login : ExternalLogin}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
