/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Form, Breadcrumb, Row, Col, Button } from 'antd';
import 'antd/dist/antd.css';
import Page from 'components/Shared/Page';
import NotificationForm from 'components/Notifications/NotificationForm';
import {
  addNotification,
  selectNotification,
  createBulkNotification,
  createBulkImportOnly,
  setErrors,
} from 'store/notification/notificationSlice';
import { isNil, isEmpty } from 'ramda';
import Notification from 'components/Notification/Notification.component';
import UploadCSV from 'components/UploadCSV/UploadCSV';
import UploadInstruction from 'components/Shared/UploadInstruction';

const StaticNotificationView = () => {
  const [content, setContent] = useState('');
  const [fileList, setFileList] = useState([]);
  const [selected, setSelected] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentNotification, isSubmitting, errors } = useSelector(
    (state) => state.notification
  );

  const handleBulkCreation = async (values) => {
    // create notification first, return the notification ID
    const res = await dispatch(addNotification({ ...values, multiple: true }));
    if (!isNil(res.payload.id)) {
      // create bulk notification by passing the user IDs and notification ID
      const data = {
        userIds: values.userIds.map((i) => Number(i)),
        notificationId: res.payload.id,
      };
      // console.log('data', data);
      const res2 = await dispatch(createBulkNotification(data));
      // console.log('res2', res2);
      if (res2) {
        // return notification message for successful ones
        Notification('notificationBulkAdded');
        history.push('/');
      }
    }
  };

  const handleCSVUploadFile = async (file) => {
    const formData = new FormData();
    formData.append('UserList', file);
    const res = await dispatch(createBulkImportOnly(formData));
    if (!isEmpty(res.payload)) {
      Notification('notificationBulkAdded');
      history.push('/');
    }
  };

  const onFinish = async (values) => {
    // console.log('Success:', values);
    // console.log('User Ids', fileList);
    const userIds = fileList.map((user) => user.UserId);
    selectNotification(values);
    if (selected === 1) {
      handleBulkCreation({ ...values, userIds });
    } else if (selected === 2) {
      handleCSVUploadFile(selectedFile);
    }
  };

  const toggleButton = (value) => {
    dispatch(setErrors([]));
    setFileList([]);
    setContent('');
    setSelected(value);
  };

  const onChange = (e) => {
    console.log(e.target.files);
    setSelectedFile(e.target.files[0]);
  };

  return (
    <Page>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/notification">Notification</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Static</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col offset={2} span={24} style={{ paddingTop: 30 }}>
          <p>
            Please select which static notification method you would like to
            add.
          </p>
          <Button
            type={selected === 1 ? 'primary' : 'default'}
            onClick={() => toggleButton(1)}
          >
            Static Form
          </Button>
          <Button
            type={selected === 2 ? 'primary' : 'default'}
            onClick={() => toggleButton(2)}
          >
            CSV only
          </Button>
        </Col>
      </Row>
      <Row justify="start" align="top">
        <Col span={24}>
          {selected === 1 && (
            <NotificationForm
              isEditMode
              onFinish={onFinish}
              content={content}
              setContent={setContent}
              nObj={currentNotification}
              isSubmitting={isSubmitting}
              errors={errors}
              staticForm
            >
              <UploadCSV
                exampleUrl="https://docs.google.com/spreadsheets/d/1UYdggz6K4vt-DqNN3DJlI868luP0cA5i6AQ2UFXJfDk/edit?usp=sharing"
                dispatch={dispatch}
                fileList={fileList}
                setFileList={setFileList}
                errors={errors}
                setErrors={setErrors}
                selected={selected}
              />
            </NotificationForm>
          )}
          {selected === 2 && (
            <>
              <Form
                style={{ margin: '1rem 0' }}
                name="notification-form"
                onFinish={onFinish}
                onChange={onChange}
              >
                <UploadCSV
                  exampleUrl="https://docs.google.com/spreadsheets/d/1usKAq2xAR3jDR1HTqjKEsEfchgxDl66r_T3U78J9yS0/edit?usp=sharing"
                  dispatch={dispatch}
                  fileList={fileList}
                  setFileList={setFileList}
                  errors={errors}
                  setErrors={setErrors}
                  selected={selected}
                />
                <Row>
                  <Col span={14}>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        className="submit-button"
                        type="primary"
                        htmlType="submit"
                        disabled={
                          isEmpty(fileList) || isSubmitting || !isEmpty(errors)
                        }
                      >
                        Submit
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
              <Row>
                <Col offset={2} span={12}>
                  <UploadInstruction />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Page>
  );
};

export default StaticNotificationView;
