import { isEmpty } from 'ramda';

const checkForDuplication = (data) => {
  const result = Object.values(
    data.reduce((c, v) => {
      const k = v.UserId;
      // eslint-disable-next-line no-param-reassign
      c[k] = c[k] || [];
      c[k].push(v);
      return c;
    }, {})
  ).reduce((c, v) => (v.length > 1 ? c.concat(v) : c), []);
  return !isEmpty(result);
};

export default checkForDuplication;
