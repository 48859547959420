import { isNil } from 'lodash';
import moment from 'moment';

export const parsedNotification = (values) => {
  const {
    id,
    title,
    contentType,
    notificationType,
    duration,
    content,
    status,
  } = values;

  const currentBackendObject = {
    id,
    title,
    contentType,
    notificationType,
    startDate: duration[0],
    endDate: duration[1],
    content,
    status,
  };

  return currentBackendObject;
};

export const parsedFilter = (values) => {
  const { id, countries, levels, plans, purchaseDate, expiryDate, isUpgraded } =
    values;

  let parsedPurchaseDate;
  let parsedExpiryDate;

  if (!isNil(purchaseDate)) {
    parsedPurchaseDate = {
      minDate: purchaseDate[0],
      maxDate: purchaseDate[1],
    };
  }

  if (!isNil(expiryDate)) {
    parsedExpiryDate = {
      minDate: expiryDate[0],
      maxDate: expiryDate[1],
    };
  }

  const currentBackendAssignObject = {
    notificationId: id,
    filter: {
      countries,
      levels,
      plans,
      purchaseDate: parsedPurchaseDate,
      expiryDate: parsedExpiryDate,
      isUpgraded,
    },
  };

  return currentBackendAssignObject;
};

export const deconstructNotification = (notiObj) => {
  const {
    id,
    title,
    contentType,
    notificationType,
    startDate,
    endDate,
    content,
    status,
    notificationId,
    filter,
  } = notiObj;

  let notiObjData = null;

  if (filter) {
    const { countries, levels, plans, purchaseDate, expiryDate, isUpgraded } =
      filter;

    let deconPurchaseDate;
    let deconExpiryDate;

    if (!isNil(purchaseDate)) {
      deconPurchaseDate = [
        moment(purchaseDate.minDate),
        moment(purchaseDate.maxDate),
      ];
    }

    if (!isNil(expiryDate)) {
      deconExpiryDate = [
        moment(expiryDate.minDate),
        moment(expiryDate.maxDate),
      ];
    }

    notiObjData = {
      content,
      countries,
      levels,
      plans,
      notificationId,
      purchaseDate: deconPurchaseDate,
      expiryDate: deconExpiryDate,
      isUpgraded,
    };
  }

  const currentObject = {
    id,
    title,
    content,
    contentType,
    notificationType,
    status,
    duration: [
      moment(startDate, 'YYYY-MM-DD HH:mm:ss'),
      moment(endDate, 'YYYY-MM-DD HH:mm:ss'),
    ],
    ...notiObjData,
  };

  return currentObject;
};
