import { notification } from 'antd';

export const condition = {
  login: {
    type: 'success',
    message: 'Success',
    description: "You're logged in successfully!",
  },
  loginError: {
    type: 'error',
    message: 'Incorrect login',
    description: 'Your username/password is incorrect.',
  },
  logout: {
    type: 'success',
    message: 'Success',
    description: "You're logged out successfully!",
  },
  notificationAdded: {
    type: 'success',
    message: 'Success',
    description: 'Notification has been added successfully.',
  },
  notificationUpdated: {
    type: 'success',
    message: 'Success',
    description: 'Notification has been updated successfully.',
  },
  notificationRemoved: {
    type: 'success',
    message: 'Success',
    description: 'Notification has been removed successfully.',
  },
  notificationBulkAdded: {
    type: 'success',
    message: 'Success',
    description: 'Bulk notification has been added successfully.',
  },
  invalidRoles: {
    type: 'error',
    message: 'Invalid Role',
    description: 'This account is not admin, please use another account.',
  },
};

const Notification = (currentCondition) => {
  const result = condition[currentCondition];

  notification[result.type]({
    message: result.message,
    description: result.description,
    duration: 3,
  });
};

export default Notification;
