import styled from 'styled-components';

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  color: #141313;
  img {
    width: 162px;
    height: 162px;
    margin: 10px 0px;
  }
  h4 {
    font-weight: 600;
    font-size: 28px;
  }
  p {
    margin-bottom: 2rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  margin: 0px;
`;

export const PreviewContent = styled.div`
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  margin: 0px;
  height: 200px;
  overflow-y: scroll;
`;
