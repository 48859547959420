import handleResponse from 'helpers/handleAPIResponse';
// eslint-disable-next-line import/no-cycle
import store from 'store/index';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

// get notifications
export const fetchNotifications = ({ currentPage, totalItemCount }) => {
  return fetch(`${baseUrl}Notification/all/${currentPage}/${totalItemCount}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (result.status === 400) {
        throw new Error(result.errors);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get all notifications failed'); // eslint-disable-line
    });
};

// get notificiation by Id
export const fetchNotificationById = ({ id }) => {
  return fetch(`${baseUrl}Notification/${id}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (result.status === 400) {
        throw new Error(result.errors);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get notification by id failed'); // eslint-disable-line
    });
};

export const fetchNotificationAssigns = ({ id }) => {
  return fetch(`${baseUrl}filter/all?NotificationId=${id}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (result.status === 400) {
        throw new Error(result.errors);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get notification by id failed'); // eslint-disable-line
    });
};

export const createNotificationData = (params) => {
  return fetch(`${baseUrl}Notification`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(params),
  })
    .then(handleResponse)
    .then((result) => {
      console.log('result', result);
      if (result.status === 400) {
        throw new Error(result.errors);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Create notification failed'); // eslint-disable-line
    });
};

export const createNotificationAssignData = (params) => {
  return fetch(`${baseUrl}filter`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(params),
  })
    .then(handleResponse)
    .then((result) => {
      if (result.status === 400) {
        throw new Error(result.errors);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Create notification failed'); // eslint-disable-line
    });
};

export const updateNotificationData = (params) => {
  const { id } = params;
  return fetch(`${baseUrl}Notification/${id}`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(params),
  })
    .then(handleResponse)
    .then((result) => {
      if (result.status === 400) {
        throw new Error(result.errors);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update notification failed'); // eslint-disable-line
    });
};

export const updateNotificationAssignData = (params) => {
  const { id } = params;
  return fetch(`${baseUrl}filter/${id}`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(params),
  })
    .then(handleResponse)
    .then((result) => {
      if (result.status === 400) {
        throw new Error(result.errors);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update notification failed'); // eslint-disable-line
    });
};

export const deleteNotificationData = (id) => {
  return fetch(`${baseUrl}Notification/${id}`, {
    method: 'DELETE',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (result.status === 500) {
        throw new Error('Not found.');
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Delete notification failed'); // eslint-disable-line
    });
};

export const createBulkNotificationData = (params) => {
  return fetch(`${baseUrl}Notification/bulk`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(params),
  })
    .then(handleResponse)
    .then((result) => {
      if (result.status === 400) {
        throw new Error(result.errors);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Create bulk notification failed'); // eslint-disable-line
    });
};

export const createBulkImportData = (data) => {
  console.log('data', data);
  return fetch(`${baseUrl}Notification/bulk/import`, {
    method: 'POST',
    headers: {
      // 'content-type': 'multipart/form-data',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: data,
  })
    .then(handleResponse)
    .then((result) => {
      console.log('result', result);
      if (result.status === 400) {
        throw new Error(result.errors);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Create bulk notification failed'); // eslint-disable-line
    });
};

// export const deleteNotificationAssignData = (id) => {
//   return fetch(`${baseUrl}filter/${id}`, {
//     method: 'DELETE',
//     headers: {
//       authorization: `Bearer ${localStorage.getItem('access_token')}`,
//       Timezone: store.getState().login.timezone,
//     },
//   })
//     .then(handleResponse)
//     .then(() => {
//       return true;
//     })
//     .catch((err) => {
//       throw new Error(err?.message ?? 'Delete notification failed'); // eslint-disable-line
//     });
// };
