import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import Page from 'components/Shared/Page';
import { Breadcrumb, Row, Col } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import NotificationForm from 'components/Notifications/NotificationForm';
// eslint-disable-next-line import/no-cycle
import {
  addNotification,
  selectNotification,
} from 'store/notification/notificationSlice';
import Notification from 'components/Notification/Notification.component';

const NotificationAdd = () => {
  const [content, setContent] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentNotification, isSubmitting } = useSelector(
    (state) => state.notification
  );

  const onFinish = async (values) => {
    console.log('Success:', values);
    dispatch(selectNotification(values));
    dispatch(addNotification(values)).then(() => {
      Notification('notificationAdded');
      history.push('/');
    });
  };

  return (
    <Page>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/notification">Notification</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add New</Breadcrumb.Item>
      </Breadcrumb>
      <Row justify="start" align="top">
        <Col span={24}>
          <NotificationForm
            isEditMode
            onFinish={onFinish}
            content={content}
            setContent={setContent}
            nObj={currentNotification}
            isSubmitting={isSubmitting}
          />
        </Col>
      </Row>
    </Page>
  );
};

export default NotificationAdd;
