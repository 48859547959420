const UploadInstruction = () => {
  return (
    <div style={{ backgroundColor: '#ffffff', padding: 20 }}>
      <h3>Instruction</h3>
      <ol>
        <li>Download the CSV file.</li>
        <li>
          Make sure the each column is filled. One row equals to one user
          notification
        </li>
        <li>
          Follow the format for each column
          <ul>
            <li>
              <b>Start Date / End Date</b>
              <ul>
                <li>dd MMM yyyy HH:mm:ss (eg. 01 Jul 2021 09:20:15)</li>
              </ul>
            </li>
            <li>
              <b>Notification Type</b> - Only 1,2,3
              <ul>
                <li>1 - Pop up</li>
                <li>2 - Page</li>
                <li>3 - Banner</li>
              </ul>
            </li>
            <li>
              <b>Title</b>
              <ul>
                <li>String format</li>
              </ul>
            </li>
            <li>
              <b>Actual Message</b>
              <ul>
                <li>HTML format</li>
              </ul>
            </li>
            <li>
              <b>User Id</b>
            </li>
            <ul>
              <li>Must be a number</li>
            </ul>
          </ul>
          <li>
            Once it is ready, select <b>Choose File</b> and click <b>Submit</b>{' '}
            button.
          </li>
        </li>
      </ol>
    </div>
  );
};

export default UploadInstruction;
