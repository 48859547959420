/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Row, Col, Button } from 'antd';
import { useParams, Link } from 'react-router-dom';
import NotificationForm from 'components/Notifications/NotificationForm';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'components/Shared/Page';
import {
  getNotificationById,
  selectNotification,
  updateNotification,
} from 'store/notification/notificationSlice';
import Notification from 'components/Notification/Notification.component';
import { isNil } from 'ramda';

const NotificationView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isLoading, currentNotification, isSubmitting, errors } = useSelector(
    (state) => state.notification
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const [content, setContent] = useState('');

  useEffect(() => {
    const getNotificationData = async () => {
      await dispatch(getNotificationById({ id }));
    };

    getNotificationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!isNil(currentNotification.content)) {
      setContent(currentNotification.content);
    }
  }, [currentNotification.content]);

  const onFinish = (values) => {
    dispatch(selectNotification({ ...values, id }));
    dispatch(updateNotification({ ...values, id })).then(() => {
      Notification('notificationUpdated');
      setIsEditMode(false);
    });
  };

  return (
    <Page>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/notification">Notification</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{id}</Breadcrumb.Item>
      </Breadcrumb>
      <Row justify="start" align="top">
        <Button
          type="primary   "
          onClick={() => {
            setIsEditMode(!isEditMode);
          }}
          style={{
            margin: '0 0 0 auto',
          }}
        >
          {isEditMode ? 'View' : 'Edit'}
        </Button>
        <Col span={24}>
          {isLoading && 'Loading...'}
          {!isLoading && (
            <NotificationForm
              isEditMode={isEditMode}
              onFinish={onFinish}
              content={content}
              setContent={setContent}
              nObj={currentNotification}
              isSubmitting={isSubmitting}
              errors={errors}
            />
          )}
        </Col>
      </Row>
    </Page>
  );
};

export default NotificationView;
