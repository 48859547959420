import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Form,
  Input,
  Button,
  DatePicker,
  Divider,
  Select,
  Radio,
  Row,
  Col,
} from 'antd';
import HTMLReactParser from 'html-react-parser';
import { isNil } from 'ramda';
import { ErrorBoundary } from 'react-error-boundary';
// import JSONPretty from 'react-json-pretty';
import ErrorResponse from 'components/Shared/ErrorResponse';
import { debounce, isEmpty } from 'lodash';
import {
  countryData,
  planData,
  levelData,
  notificationTypeData,
} from 'constants/data';
import {
  EmptyContent,
  Content,
  PreviewContent,
} from './NotificationForm.styles';

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const formItemLayout = {
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 12 },
  },
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
};

const ComponentThatMayError = ({ error }) => {
  const errorFunc = () => {
    if (error === true) {
      throw new Error('error');
    }
  };

  return <div>{errorFunc()}</div>;
};

const PreviewContentData = ({ content, error, setContent, setError }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorResponse}
      onReset={() => {
        setContent('');
        setError(false);
      }}
    >
      <ComponentThatMayError error={error} />
      <Content>{HTMLReactParser(content)}</Content>
    </ErrorBoundary>
  );
};

const NotificationForm = ({
  isEditMode,
  onFinish,
  content,
  setContent,
  nObj,
  isSubmitting,
  staticForm,
  children,
  errors,
}) => {
  const { wrapperCol, labelCol } = formItemLayout;

  const form = useRef(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    form.current.setFieldsValue(nObj);
  }, [nObj]);

  const onOk = (value) => {
    console.log('onOk: ', value);
  };

  const handleChange = (e) => {
    // form.current.setFieldsValue({ content: e.target.value });
    setContent(e.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePreview = useCallback(debounce(handleChange, 1000));

  const handleSubscription = (value) => {
    if (value.includes('ALL')) {
      form.current.setFieldsValue({
        plans: planData.map((plan) => plan.id),
      });
    } else {
      form.current.setFieldsValue({ plans: value });
    }
  };

  const handleNotificationType = (value) => {
    form.current.setFieldsValue({ notificationType: value });
  };

  const handleCountry = (value) => {
    if (value.includes('ALL')) {
      form.current.setFieldsValue({
        countries: countryData.map((country) => country.id),
      });
    } else {
      form.current.setFieldsValue({ countries: value });
    }
  };

  const handleLevel = (value) => {
    if (value.includes('ALL')) {
      form.current.setFieldsValue({
        levels: levelData.map((level) => level.id),
      });
    } else {
      form.current.setFieldsValue({ levels: value });
    }
  };

  const onChangeRadio = (e) => {
    console.log('radio checked', e.target.value);
  };

  return (
    <>
      <Form
        ref={form}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        style={{ margin: '1rem 0' }}
        name="notification-form"
        // initialValues={{
        //   content: '234',
        // }}
        onFinish={onFinish}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: 'The title can not be blank',
            },
          ]}
        >
          <Input id="title" disabled={!isEditMode} />
        </Form.Item>
        <Form.Item
          label="Start/End Date"
          name="duration"
          rules={[
            {
              required: true,
              message: 'The start/end date can not be blank',
            },
          ]}
        >
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            onOk={onOk}
            disabled={!isEditMode}
          />
        </Form.Item>
        <Form.Item label="ContentType" name="contentType" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item label="Status" name="status" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          label="Notification Type"
          name="notificationType"
          rules={[
            {
              required: true,
              message: 'The notification type can not be blank',
            },
          ]}
        >
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            onChange={handleNotificationType}
            disabled={!isEditMode}
          >
            {notificationTypeData.map((notificationType) => (
              <Option key={notificationType.id} value={notificationType.id}>
                {notificationType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item
              label="HTML Content"
              labelCol={{ span: 4 }}
              onChange={handlePreview}
              name="content"
              rules={[
                {
                  required: true,
                  message: 'The content can not be blank',
                },
              ]}
            >
              <TextArea
                className="preview-text"
                rows={10}
                disabled={!isEditMode}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h4>Preview</h4>
            <PreviewContent>
              {!isNil(content) ? (
                <PreviewContentData
                  content={content}
                  error={error}
                  setContent={setContent}
                  setError={setError}
                />
              ) : (
                <EmptyContent>
                  <p>Waiting for magic to happen...</p>
                </EmptyContent>
              )}
            </PreviewContent>
            {/* <button type="button" onClick={() => setError(true)}>
              Do not touch this button!
            </button> */}
          </Col>
        </Row>
        {!staticForm && (
          <>
            <Divider orientation="left">User Assignment</Divider>
            <Form.Item label="Subscription Plan" name="plans">
              <Select
                className="subscription-plan"
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                onChange={handleSubscription}
                disabled={!isEditMode}
              >
                <Option key="ALL">All plans</Option>
                {planData.map((plan) => (
                  <Option key={plan.id} value={plan.id}>
                    {plan.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Country" name="countries">
              <Select
                className="country-select"
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                onChange={handleCountry}
                disabled={!isEditMode}
              >
                <Option key="ALL">All countries</Option>
                {countryData.map((country) => (
                  <Option key={country.id} value={country.id}>
                    {country.countryName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Level" name="levels">
              <Select
                className="level-select"
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                onChange={handleLevel}
                disabled={!isEditMode}
              >
                <Option key="ALL">All levels</Option>
                {levelData.map((level) => (
                  <Option key={level.id} value={level.id}>
                    {level.levelName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Purchase Date" name="purchaseDate">
              <RangePicker
                format="YYYY-MM-DD"
                // onChange={onChangePurchase}
                disabled={!isEditMode}
                onOk={onOk}
              />
            </Form.Item>
            <Form.Item label="Expiry Date" name="expiryDate">
              <RangePicker
                format="YYYY-MM-DD"
                // onChange={onChangeExpiry}
                onOk={onOk}
                disabled={!isEditMode}
              />
            </Form.Item>
            <Form.Item label="Is it upgraded?" name="isUpgraded">
              <Radio.Group disabled={!isEditMode} onChange={onChangeRadio}>
                <Radio value>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </>
        )}
        {staticForm && children}
        {isEditMode && (
          <Form.Item justify="end">
            <Button
              className="submit-button"
              type="primary"
              htmlType="submit"
              disabled={isSubmitting || !isEmpty(errors)}
            >
              Submit
            </Button>
          </Form.Item>
        )}
      </Form>
      {/* <div>
        <h4>Notification values:</h4>
        <JSONPretty id="json-pretty" data={nObj} />
      </div> */}
    </>
  );
};

export default NotificationForm;
