/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { v1 as uuidv4 } from 'uuid';
import {
  fetchNotifications,
  fetchNotificationById,
  fetchNotificationAssigns,
  createNotificationData,
  createNotificationAssignData,
  updateNotificationData,
  updateNotificationAssignData,
  deleteNotificationData,
  createBulkNotificationData,
  createBulkImportData,
} from 'services/notifications';
import { notificationObj } from 'constants/initialNotification';
import { fetchWrapper } from 'services/login';
import {
  deconstructNotification,
  parsedFilter,
  parsedNotification,
} from 'helpers/parsedNotification';
import { isNil } from 'ramda';

export const getNotifications = createAsyncThunk(
  'notifications/getAll',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchNotifications, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get all notifications failed');
    }
  }
);

export const getNotificationById = createAsyncThunk(
  'notifications/getNotificationById',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchNotificationById, params);
      const res2 = await fetchWrapper(fetchNotificationAssigns, params);
      const notiAssignObj = res2.find(
        (notiAssign) => notiAssign.notificationId === params.id
      );
      if (!isNil(notiAssignObj)) {
        return { ...res, ...notiAssignObj };
      }
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get notification failed');
    }
  }
);

export const addNotification = createAsyncThunk(
  'notifications/addNotification',
  async (params) => {
    try {
      const dataObject = parsedNotification(params);
      const dataAssignObject = parsedFilter(params);

      const result = await fetchWrapper(createNotificationData, dataObject);
      if (!isNil(result) && isNil(params.multiple)) {
        await fetchWrapper(createNotificationAssignData, {
          ...dataAssignObject,
          notificationId: result.id,
        });
        return { ...params, id: result.id };
      }
      return { ...params, id: result.id };
    } catch (error) {
      throw new Error(error?.message ?? 'Add notification failed');
    }
  }
);

export const updateNotification = createAsyncThunk(
  'notifications/updateNotification',
  async (params) => {
    try {
      const dataObject = parsedNotification(params);
      const dataAssignObject = parsedFilter(params);
      await fetchWrapper(updateNotificationData, dataObject);
      const res2 = await fetchWrapper(fetchNotificationAssigns, params);
      const notiAssignObj = res2.find(
        (notiAssign) => notiAssign.notificationId === params.id
      );
      await fetchWrapper(updateNotificationAssignData, {
        ...dataAssignObject,
        id: notiAssignObj.id,
      });
      return params;
    } catch (error) {
      throw new Error(error?.message ?? 'Update notification failed');
    }
  }
);

export const removeNotification = createAsyncThunk(
  'notifications/removeNotification',
  async (id) => {
    try {
      const res = await fetchWrapper(deleteNotificationData, id);
      return {
        id,
        result: res.result,
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Remove notification failed');
    }
  }
);

export const createBulkNotification = createAsyncThunk(
  'notifications/createBulkNotification',
  async (params) => {
    try {
      const res = await fetchWrapper(createBulkNotificationData, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Create bulk notification failed');
    }
  }
);

export const createBulkImportOnly = createAsyncThunk(
  'notifications/createBulkImportOnly',
  async (params) => {
    try {
      const res = await fetchWrapper(createBulkImportData, params);
      return res;
    } catch (error) {
      throw new Error(
        error?.message ?? 'Create bulk notification import failed'
      );
    }
  }
);

const initialState = {
  notifications: {
    item: [],
    metaData: {
      pageNumber: 1,
      totalItemCount: 10,
    },
  },
  notificationsLoading: false,
  notificationsError: null,
  filteredNotifications: [],
  isLoading: false,
  isError: null,
  errors: [],
  isSubmitting: false,
  currentNotification: notificationObj,
};

const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    resetNotification: (state) => {
      state.currentNotification = notificationObj;
    },
    selectNotification: (state, { payload }) => {
      state.currentNotification = payload;
    },
    setErrors: (state, { payload }) => {
      state.errors = payload;
    },
  },
  extraReducers: {
    [getNotifications.pending]: (state) => {
      state.notificationsLoading = true;
      state.notifications.item = [];
      state.notificationsError = null;
    },
    [getNotifications.fulfilled]: (state, { payload }) => {
      state.notificationsLoading = false;
      state.notifications = {
        ...payload,
        item: payload.item.sort((a, b) => {
          const dateA = new Date(a.startDate);
          const dateB = new Date(b.startDate);
          return dateB - dateA;
        }),
      };
      state.notificationsError = null;
    },
    [getNotifications.rejected]: (state, action) => {
      state.notificationsLoading = false;
      state.notifications.item = [];
      state.notificationsError = action.error.message;
    },
    [getNotificationById.pending]: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    [getNotificationById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.currentNotification = deconstructNotification(action.payload);
      state.isError = null;
    },
    [getNotificationById.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
    [addNotification.pending]: (state) => {
      state.isError = null;
      state.isSubmitting = true;
    },
    [addNotification.fulfilled]: (state, action) => {
      state.currentNotification = action.payload;
      state.notifications.item.push(action.payload);
      state.isError = null;
      state.isSubmitting = false;
    },
    [addNotification.rejected]: (state, action) => {
      state.isError = action.error.message;
      state.isSubmitting = false;
    },
    [updateNotification.pending]: (state) => {
      state.isError = null;
      state.isSubmitting = true;
    },
    [updateNotification.fulfilled]: (state, action) => {
      state.currentNotification = action.payload;
      state.notifications.item = state.notifications.item.map((notification) =>
        notification.id === action.payload.id ? action.payload : notification
      );
      state.isError = null;
      state.isSubmitting = false;
    },
    [updateNotification.rejected]: (state, action) => {
      state.isError = action.error.message;
      state.isSubmitting = false;
    },
    [removeNotification.pending]: (state) => {
      state.isError = null;
    },
    [removeNotification.fulfilled]: (state, { payload }) => {
      if (payload.result === true) {
        const index = state.notifications.item.findIndex(
          (notification) => notification.id === payload.id
        );
        if (index !== -1) {
          state.notifications.item.splice(index, 1);
        }
      }
      state.isError = null;
    },
    [removeNotification.rejected]: (state, action) => {
      state.isError = action.error.message;
    },
  },
});

export const { resetNotification, selectNotification, setErrors } =
  notificationSlice.actions;

const { reducer } = notificationSlice;
export default reducer;
