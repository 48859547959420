/* eslint-disable import/no-cycle */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import loginReducer from 'store/login/loginSlice';
import notificationReducer from 'store/notification/notificationSlice';
import { createLogger } from 'redux-logger';

const middleware = [
  /* YOUR CUSTOM MIDDLEWARES HERE */
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
];

if (process.env.NODE_ENV !== 'production') {
  const logger = createLogger();
  // add logger middleware
  middleware.push(logger);
}

const combinedReducer = combineReducers({
  login: loginReducer,
  notification: notificationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'reset-store') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

export default store;
